import React, { Component } from 'react';
import ReactImageMagnify from 'react-image-magnify';
import ReactSlick from 'react-slick';

import '../styles/react-slick.css';



export default class ReactSlickExample extends Component {
  constructor() {
   super();
   this.state = {
     name: 'React',
     hover: ""
   };
 }
 onMouseEnter() {
   setTimeout(
   function() {
       this.setState({ hover: true });
   }
   .bind(this),
   500
);
  }

  onMouseLeave() {
    this.setState({hover: false})
  }
  render() {
    const {
      rimProps,
      rsProps,
      photos
    } = this.props;



    const homepageCheck = this.props.arrows



    return (
      <div className="carousel-size" >


        <ReactSlick
          {...{

            dots: false,
            arrows: homepageCheck,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 10000,

            speed: 1200,
            slidesToShow: 1,
            slidesToScroll: 1,
            adaptiveHeight: true
          }}
          {...rsProps}
        >
          {this.props.photos.map((src, index) => (
            <div>
              <div key={index} onMouseEnter={() => this.onMouseEnter()} onMouseLeave={() => this.onMouseLeave()}>

                { homepageCheck ?   <ReactImageMagnify
                  {...{
                    smallImage: {
                        alt: 'Art By Sam Mills',
                        isFluidWidth: true,


                        src: src.small,
                        srcSet: src.srcSet,
                        sizes: '(max-width: 480px) 100vw, (max-width: 1200px) 30vw, 60px'
                    },
                    largeImage: {
                        src: src.large,
                        isFluidWidth: true,
                        width: 2420,
                        height: 1936
                    },
                    lensStyle: { backgroundColor: 'rgba(0,0,0,.6)' }
                  }}
                  {...rimProps}
                                    ></ReactImageMagnify>
                : <div className="no-mag"><ReactImageMagnify
                  {...{
                    smallImage: {
                        alt: 'Art By Sam Mills',
                        isFluidWidth: true,


                        src: src.small,
                        srcSet: src.srcSet,
                        sizes: '(max-width: 480px) 100vw, (max-width: 1200px) 30vw, 60px'
                    },
                    largeImage: {
                        src: src.large,
                        isFluidWidth: true,
                        width: 2420,
                        height: 1936
                    },
                    lensStyle: { backgroundColor: 'rgba(0,0,0,.6)' }
                  }}
                  {...rimProps}
                       ></ReactImageMagnify></div>}





              </div>
              {this.state.hover && homepageCheck ? <div className="art-text-wrapper"><span className="art-text art-title"> {this.props.photos[index].text.title}</span>, <span className="art-text art-info"> {this.props.photos[index].text.info}</span></div> : <p></p>}
            </div>
          ))}
        </ReactSlick>
      </div>
      );
    }
  }
