import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function Footer() {
  return (
    <footer className="footer-bottom pt-3">
      <p className="copyright-text text-right">
        {" "}
        All images Copyright Samuel Alexander Mills &#169;
      </p>
    </footer>
  );
}

export default Footer;
