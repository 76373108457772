// Customize this 'ContactForm.js' script and add it to your JS bundle.
// Then import it with 'import ContactForm from "./ContactForm.js"'.
// Finally, add a <ContactForm/> element whereever you wish to display the form.

import React from "react";

export default class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.state = {
      status: "",
    };
  }

  render() {
    const { status } = this.state;
    return (
      <form
        className=""
        onSubmit={this.submitForm}
        action="https://formspree.io/maypvlwg"
        method="POST"
      >
        <label className="pt-2 mt-2 d-inline-block"> Name </label>
        <input className="w-100 d-inline-block" type="text" name="name" />
        <label className="pt-2 mt-2 d-block-block">Email</label>
        <input className="w-100 d-inline-block" type="email" name="email" />
        <label className="pt-2 mt-2 d-block-block">Message</label>
        <label className="py-2 d-block-block w-100">
          <textarea
            class="d-block-block w-100 message-area"
            name="message"
            id="message"
            cols="30"
            rows="3"
          ></textarea>
        </label>
        {status === "SUCCESS" ? (
          <p className="float-right">Message Sent.</p>
        ) : (
          <button className="contact-submit">Send</button>
        )}
        {status === "ERROR" && <p className="p-2">Message failed to send.</p>}
      </form>
    );
  }

  submitForm(ev) {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        this.setState({ status: "SUCCESS" });
      } else {
        this.setState({ status: "ERROR" });
      }
    };
    xhr.send(data);
  }
}
