import React from "react";

import Hero from "../components/Hero";
import Carousel from "../components/Carousel";

import img_1_small from "../assets/images/2020/small/sam-mills-art-2020-a1.jpg";
import img_1_large from "../assets/images/2020/large/sam-mills-art-2020-a.jpg";

import img_2_small from "../assets/images/2020/small/sam-mills-art-2020-b1.jpg";
import img_2_large from "../assets/images/2020/large/sam-mills-art-2020-b.jpg";

import img_3_small from "../assets/images/2020/small/sam-mills-art-2020-c1.jpg";
import img_3_large from "../assets/images/2020/large/sam-mills-art-2020-c.jpg";
import img_13_small from "../assets/images/2021/small/bloom.jpg";
import img_13_large from "../assets/images/2021/large/bloom.jpg";

import img_14_small from "../assets/images/2021/small/tvdinner.jpg";
import img_14_large from "../assets/images/2021/large/tvdinner.jpg";

import img_15_small from "../assets/images/2021/small/untitled24.jpg";
import img_15_large from "../assets/images/2021/large/untitled24.jpg";

// import img_4_small from "../assets/images/2019/small/sam-mills-art-2019-f1.jpg";
// import img_4_large from "../assets/images/2019/large/sam-mills-art-2019-f.jpg";

// import img_5_small from "../assets/images/2020/small/sam-mills-art-2020-e1.jpg";
// import img_5_large from "../assets/images/2020/large/sam-mills-art-2020-e.jpg";

// import img_6_small from "../assets/images/2020/small/sam-mills-art-2020-f1.jpg";
// import img_6_large from "../assets/images/2020/large/sam-mills-art-2020-f.jpg";

// //2019 images

// import img_8_small from "../assets/images/2019/small/sam-mills-art-2019-a1.jpg";
// import img_8_large from "../assets/images/2019/large/sam-mills-art-2019-a.jpg";

// import img_9_small from "../assets/images/2019/small/sam-mills-art-2019-b1.jpg";
// import img_9_large from "../assets/images/2019/large/sam-mills-art-2019-b.jpg";

// import img_10_small from "../assets/images/2019/small/sam-mills-art-2019-c1.jpg";
// import img_10_large from "../assets/images/2019/large/sam-mills-art-2019-c.jpg";

// import img_11_small from "../assets/images/2019/small/sam-mills-art-2019-d1.jpg";
// import img_11_large from "../assets/images/2019/large/sam-mills-art-2019-d.jpg";

// import img_12_small from "../assets/images/2019/small/sam-mills-art-2019-e1.jpg";
// import img_12_large from "../assets/images/2019/large/sam-mills-art-2019-e.jpg";

const img1SrcSet = [
  { src: img_13_small, setting: "500w" },
  { src: img_13_large, setting: "1426w" },
]
  .map((item) => `${item.src} ${item.setting}`)
  .join(", ");

const img2SrcSet = [
  { src: img_14_small, setting: "500w" },
  { src: img_14_large, setting: "1426w" },
]
  .map((item) => `${item.src} ${item.setting}`)
  .join(", ");

const img3SrcSet = [
  { src: img_15_small, setting: "500w" },
  { src: img_15_large, setting: "1426w" },
]
  .map((item) => `${item.src} ${item.setting}`)
  .join(", ");

// const img4SrcSet = [
//   { src: img_4_small, setting: "500w" },
//   { src: img_4_large, setting: "1426w" },
// ]
//   .map((item) => `${item.src} ${item.setting}`)
//   .join(", ");

// const img5SrcSet = [
//   { src: img_5_small, setting: "500w" },
//   { src: img_5_large, setting: "1426w" },
// ]
//   .map((item) => `${item.src} ${item.setting}`)
//   .join(", ");

// const img6SrcSet = [
//   { src: img_6_small, setting: "500w" },
//   { src: img_6_large, setting: "1426w" },
// ]
//   .map((item) => `${item.src} ${item.setting}`)
//   .join(", ");

// const img8SrcSet = [
//   { src: img_8_small, setting: "500w" },
//   { src: img_8_large, setting: "1426w" },
// ]
//   .map((item) => `${item.src} ${item.setting}`)
//   .join(", ");

// const img9SrcSet = [
//   { src: img_9_small, setting: "500w" },
//   { src: img_9_large, setting: "1426w" },
// ]
//   .map((item) => `${item.src} ${item.setting}`)
//   .join(", ");

// const img10SrcSet = [
//   { src: img_10_small, setting: "500w" },
//   { src: img_10_large, setting: "1426w" },
// ]
//   .map((item) => `${item.src} ${item.setting}`)
//   .join(", ");

// const img11SrcSet = [
//   { src: img_11_small, setting: "500w" },
//   { src: img_11_large, setting: "1426w" },
// ]
//   .map((item) => `${item.src} ${item.setting}`)
//   .join(", ");

// const img12SrcSet = [
//   { src: img_12_small, setting: "500w" },
//   { src: img_12_large, setting: "1426w" },
// ]
//   .map((item) => `${item.src} ${item.setting}`)
//   .join(", ");

const all_images = [
  {
    srcSet: img1SrcSet,
    small: img_1_small,
    large: img_1_large,
    text: "image 1",
  },
  {
    srcSet: img2SrcSet,
    small: img_2_small,
    large: img_2_large,
    text: "image 2",
  },
  {
    srcSet: img3SrcSet,
    small: img_3_small,
    large: img_3_large,
    text: "image 3",
  }
];

function HomePage(props) {
  return <Carousel photos={all_images} arrows={false} autoPlayInterval={100} />;
}

export default HomePage;
