import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Card from "../components/Card";
import ReactSlickExample from "../components/ReactSlickIntegration";

class Carousel extends React.Component {
  makeItems = (items) => {
    return items.map((item) => {
      return (
        <Card
          item={item}
          onClick={(e) => this.handleCardClick(items.id, e)}
          key={item.id}
        />
      );
    });
  };

  render() {
    const dataSource2020 = this.props.photos;
    const arrows = this.props.arrows;
    return (
      <div className="content-box">
        <ReactSlickExample
          photos={dataSource2020}
          arrows={arrows}
        ></ReactSlickExample>
      </div>
    );
  }
}

export default Carousel;
