import React from 'react';
import Carousel from '../components/Carousel'

//2020 images

import img_1_small from '../assets/images/2020/small/sam-mills-art-2020-a1.jpg'
import img_1_large from '../assets/images/2020/large/sam-mills-art-2020-a.jpg'

import img_2_small from '../assets/images/2020/small/sam-mills-art-2020-b1.jpg'
import img_2_large from '../assets/images/2020/large/sam-mills-art-2020-b.jpg'

import img_3_small from '../assets/images/2020/small/sam-mills-art-2020-c1.jpg'
import img_3_large from '../assets/images/2020/large/sam-mills-art-2020-c.jpg'


import img_5_small from '../assets/images/2020/small/sam-mills-art-2020-e1.jpg'
import img_5_large from '../assets/images/2020/large/sam-mills-art-2020-e.jpg'

import img_6_small from '../assets/images/2020/small/sam-mills-art-2020-f1.jpg'
import img_6_large from '../assets/images/2020/large/sam-mills-art-2020-f.jpg'




const img1SrcSet = [
  { src: img_1_small, setting: '500w' },
  { src: img_1_large, setting: '1426w' }
]
.map(item => `${item.src} ${item.setting}`)
.join(', ');

const img2SrcSet = [
  { src: img_2_small, setting: '500w' },
  { src: img_2_large, setting: '1426w' }
]
.map(item => `${item.src} ${item.setting}`)
.join(', ');

const img3SrcSet = [
  { src: img_3_small, setting: '500w' },
  { src: img_3_large, setting: '1426w' }
]
.map(item => `${item.src} ${item.setting}`)
.join(', ');



const img5SrcSet = [
  { src: img_5_small, setting: '500w' },
  { src: img_5_large, setting: '1426w' }
]
.map(item => `${item.src} ${item.setting}`)
.join(', ');

const img6SrcSet = [
  { src: img_6_small, setting: '500w' },
  { src: img_6_large, setting: '1426w' }
]
.map(item => `${item.src} ${item.setting}`)
.join(', ');






const photo2020 = [
  {
    srcSet: img1SrcSet,
    small: img_1_small,
    large: img_1_large,
    text: {
      title: '.\'Mutiny:Redacted\'',
      info: '2020 183 x 152cm Oil & Spray Paint on stitched canvas (Gossip Series)',
    }
  },
  {
    srcSet: img2SrcSet,
    small: img_2_small,
    large: img_2_large,
    text: {
      title: '.\'Design:Committee\'',
      info: '2020 150 x 120cm Oil & Spray Paint on stitched canvas (Gossip Series)',
    }
  },
  {
    srcSet: img3SrcSet,
    small: img_3_small,
    large: img_3_large,
    text: {
      title: '.\'Mad:hatten\'',
      info: '2020 185 x 160cm Oil Stick & Acrylic on stitched canvas',
    }
  },
  {
    srcSet: img5SrcSet,
    small: img_5_small,
    large: img_5_large,
    text: {
      title: '.\'Untitled:Bust\'',
      info: '2020 165 x 165cm Oil & Spray Paint on stitched canvas (Gossip Series)',
    }
  },
  {
    srcSet: img6SrcSet,
    small: img_6_small,
    large: img_6_large,
    text: {
      title: '.\'Idle:Idol\'',
      info: '2020 165 x 165cm Oil & Spray Paint on stitched canvas (Gossip Series)',
    }
  }

];



function WorksPage(props) {
  return (
        <Carousel photos={photo2020} arrows={true} />
  );
}

export default WorksPage;
