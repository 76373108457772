import React from "react";
import Carousel from "../components/Carousel";

//2021 + images

import img_13_small from "../assets/images/2021/small/bloom.jpg";
import img_13_large from "../assets/images/2021/large/bloom.jpg";

import img_14_small from "../assets/images/2021/small/tvdinner.jpg";
import img_14_large from "../assets/images/2021/large/tvdinner.jpg";

import img_15_small from "../assets/images/2021/small/untitled24.jpg";
import img_15_large from "../assets/images/2021/large/untitled24.jpg";

const img7SrcSet = [
  { src: img_13_small, setting: "500w" },
  { src: img_13_large, setting: "1426w" },
]
  .map((item) => `${item.src} ${item.setting}`)
  .join(", ");

const img8SrcSet = [
  { src: img_14_small, setting: "500w" },
  { src: img_14_large, setting: "1426w" },
]
  .map((item) => `${item.src} ${item.setting}`)
  .join(", ");

const img9SrcSet = [
  { src: img_15_small, setting: "500w" },
  { src: img_15_large, setting: "1426w" },
]
  .map((item) => `${item.src} ${item.setting}`)
  .join(", ");

const photo2021 = [
  {
    srcSet: img7SrcSet,
    small: img_13_small,
    large: img_13_large,
    text: {
      title: ".'Bloom or Bust'",
      info: `2023. 190 x 130cm Oil, Oil Stick, Spray paint, Graphite pencil on stitched canvas`,
    },
  },
  {
    srcSet: img8SrcSet,
    small: img_14_small,
    large: img_14_large,
    text: {
      title: ".'TV Dinner'",
      info: ` 2024. 185 x 130cm Oil, Oil Stick, Acrylic, Spray Paint, Graphite pencil on stitched canvas`,
    },
  },
  {
    srcSet: img9SrcSet,
    small: img_15_small,
    large: img_15_large,
    text: {
      title: ".'Succession'",
      info: `2022-24. 190 x 145cm Oil, Oil Stick on canvas`,
    },
  },
];

function WorksPage(props) {
  return <Carousel photos={photo2021} arrows={true} />;
}

export default WorksPage;
