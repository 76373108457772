import React from "react";

function AboutPage(props) {
  return (
    <div className="content-box-sans-photo about-text pt-5">
      <p>
        <b>Samuel Alexander Mills b1987</b> is a British abstract artist whose
        work addresses misrepresentation and censorship, reflecting on how
        information is remembered, shared and received – a commentary on the
        abstraction of truth.
      </p>
      <p>
        Working predominantly on the studio floor with oil on both sides of the
        canvas, his creative process includes the intentional cutting,
        defacement, reversal and removal of whole sections of visual
        information. These seemingly destructive acts are combined with a
        restitching process that provides a revealing commentary on the abstract
        layers of narrative construction. What emerges from this process is a
        visual dialogue that mirrors the ebb and flow of experience.
      </p>
      <p>
        With an artistic foundation marked by a BA Hons in Illustration from
        Southampton Solent University, his work evolved naturally into a fine
        art pursuit - a direct consequence of addressing and expanding upon his
        conceptual interests.
      </p>
      <p>
        Born and based in London, he currently works from his studio in London
        Fields.{" "}
      </p>
    </div>
  );
}

export default AboutPage;
