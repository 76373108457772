import React, { Component } from 'react';
import { Link } from 'react-router';
import ReactSlick from '../components/ReactSlick';
import SpacedSpan from '../components/SpacedSpan';

import '../styles/app.css';




export default class ReactSlickExample extends Component {
    render() {
      const use_photos = this.props.photos;
      const arrows = this.props.arrows;




        return (
            <div className="fluid align-self-center react-slick">
              <div className="fluid__image-container align-self-center">
                <ReactSlick photos={use_photos}  {...{
                  rimProps: {
                    isHintEnabled: false,
                    shouldHideHintAfterFirstActivation: true,
                    enlargedImagePosition: 'over'
                  }
                }} arrows={arrows} />
                </div>


            </div>
        );
    }
}
