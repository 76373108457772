import React from "react";
import { BrowserRouter as Router, Route, NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import "./App.css";

import Footer from "./components/Footer";
import HomePage from "./pages/HomePage";
import AboutPage from "./pages/AboutPage";
import WorksPage from "./pages/WorksPage";
import ContactPage from "./pages/ContactPage";
import Year2021Page from "./pages/Year2021Page";
import Year2020Page from "./pages/Year2020Page";
import Year2019Page from "./pages/Year2019Page";

import logo from "./assets/images/stamp.png";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      title: "Sam Mills Art",
      headerLinks: [
        { title: "Home", path: "/" },
        { title: "About", path: "/about" },
        { title: "Works", path: "/works" },
        { title: "Contact", path: "/contact" },
      ],
      home: {
        title: "Samuel Mills Art",
      },
      about: {
        title: "About Sam Mills",
      },
      works: {
        title: "My Work",
      },
      contact: {
        title: "Let's talk",
      },
    };
  }

  render() {
    return (
      <Router>
        <NavLink
          onClick={function () {
            this.setState({ open: false });
          }.bind(this)}
          className="mobile-header-logo"
          activeClassName="active-link"
          to="/"
        >
          <img src={logo} alt="logo" width="120px" />
        </NavLink>
        <div className="page-container">
          <Container className="p-0 content-wrap" fluid={true}>
            <Row>
              <Col md={2} lg={1}>
                <Navbar
                  collapseOnSelect
                  className="verticle-nav"
                  id="sidebar"
                  bg="transparent"
                  expand="lg"
                >
                  <Navbar.Toggle
                    className="border-0 px-0"
                    aria-controls="navbar-toggle"
                  />
                  <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="navbar pt-0">
                      <ul className="nav flex-column navbar-nav pl-2">
                        <li className="nav-item mobile-header-logo-off">
                          <Nav.Link
                            eventKey="1"
                            as={Link}
                            onClick={function () {
                              this.setState({ open: false });
                            }.bind(this)}
                            className="nav-link "
                            activeClassName="active-link"
                            to="/"
                          >
                            <img src={logo} alt="logo" width="120px" />
                          </Nav.Link>
                        </li>
                        <li className="nav-item">
                          <Nav.Link
                            eventKey="2"
                            as={Link}
                            onClick={function () {
                              this.setState({ open: false });
                            }.bind(this)}
                            className="nav-link"
                            activeClassName="active-link"
                            to="/about"
                          >
                            ABOUT
                          </Nav.Link>
                        </li>
                        <li className="nav-item">
                          <Nav.Link
                            className="nav-link"
                            activeClassName="active-link"
                            as={Link}
                            onClick={function () {
                              this.setState({ open: !this.state.open });
                            }.bind(this)}
                            to="/works"
                          >
                            WORKS
                          </Nav.Link>
                        </li>
                        <div
                          className={
                            this.state.open
                              ? "panel-collapse"
                              : "panel-collapse panel-close"
                          }
                        >
                          <li className="nav-item">
                            <Nav.Link
                              eventKey="5"
                              as={Link}
                              className="nav-link pl-4"
                              activeClassName="active-link"
                              to="/works/i"
                            >
                              Works I
                            </Nav.Link>
                          </li>
                          <li className="nav-item">
                            <Nav.Link
                              eventKey="4"
                              as={Link}
                              className="nav-link pl-4"
                              activeClassName="active-link"
                              to="/works/ii"
                            >
                              Works II
                            </Nav.Link>
                          </li>
                          <li className="nav-item">
                            <Nav.Link
                              eventKey="10"
                              as={Link}
                              className="nav-link pl-4"
                              activeClassName="active-link"
                              to="/works/iii"
                            >
                              Works III
                            </Nav.Link>
                          </li>
                        </div>

                        <li className="nav-item">
                          <Nav.Link
                            eventKey="6"
                            as={Link}
                            onClick={function () {
                              this.setState({ open: false });
                            }.bind(this)}
                            className="nav-link"
                            activeClassName="active-link"
                            to="/contact"
                          >
                            CONTACT
                          </Nav.Link>
                        </li>
                        <li className="nav-item">
                          <span
                            className="nav-link"
                            activeClassName="active-link"
                          >
                            <a
                              className="instagram-link"
                              href="https://www.instagram.com/samstudioart"
                            >
                              INSTAGRAM
                            </a>
                          </span>
                        </li>
                      </ul>
                    </Nav>
                  </Navbar.Collapse>
                </Navbar>
              </Col>
              <Col md={10} lg={11} sm={12} className="pt-5 max-width-mobile">
                <Route
                  path="/"
                  exact
                  render={() => (
                    <HomePage
                      title={this.state.home.title}
                      subTitle={this.state.home.subTitle}
                      text={this.state.home.text}
                    />
                  )}
                />
                <Route
                  path="/about"
                  exact
                  render={() => (
                    <AboutPage
                      title={this.state.about.title}
                      text={this.state.about.text}
                    />
                  )}
                />
                <Route
                  path="/works"
                  exact
                  render={() => (
                    <WorksPage
                      title={this.state.works.title}
                      text={this.state.works.text}
                    />
                  )}
                />
                <Route
                  path="/works/i"
                  exact
                  render={() => (
                    <Year2021Page
                      title={this.state.works.title}
                      text={this.state.works.text}
                    />
                  )}
                />
                <Route
                  path="/works/ii"
                  exact
                  render={() => (
                    <Year2020Page
                      title={this.state.works.title}
                      text={this.state.works.text}
                    />
                  )}
                />
                <Route
                  path="/works/iii"
                  exact
                  render={() => (
                    <Year2019Page
                      title={this.state.works.title}
                      text={this.state.works.text}
                    />
                  )}
                />
                <Route
                  path="/contact"
                  exact
                  render={() => (
                    <ContactPage
                      title={this.state.contact.title}
                      text={this.state.contact.text}
                    />
                  )}
                />
              </Col>
            </Row>
          </Container>
          <Footer />
        </div>
      </Router>
    );
  }
}

export default App;
