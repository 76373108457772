import React from 'react';
import Carousel from '../components/Carousel'




//2019 images

import img_7_small from '../assets/images/2019/small/sam-mills-art-2019-f1.jpg'
import img_7_large from '../assets/images/2019/large/sam-mills-art-2019-f.jpg'

import img_8_small from '../assets/images/2019/small/sam-mills-art-2019-a1.jpg'
import img_8_large from '../assets/images/2019/large/sam-mills-art-2019-a.jpg'

import img_9_small from '../assets/images/2019/small/sam-mills-art-2019-b1.jpg'
import img_9_large from '../assets/images/2019/large/sam-mills-art-2019-b.jpg'

import img_10_small from '../assets/images/2019/small/sam-mills-art-2019-c1.jpg'
import img_10_large from '../assets/images/2019/large/sam-mills-art-2019-c.jpg'

import img_11_small from '../assets/images/2019/small/sam-mills-art-2019-d1.jpg'
import img_11_large from '../assets/images/2019/large/sam-mills-art-2019-d.jpg'

import img_12_small from '../assets/images/2019/small/sam-mills-art-2019-e1.jpg'
import img_12_large from '../assets/images/2019/large/sam-mills-art-2019-e.jpg'



const img7SrcSet = [
  { src: img_7_small, setting: '500w' },
  { src: img_7_large, setting: '1426w' }
]
.map(item => `${item.src} ${item.setting}`)
.join(', ');


const img8SrcSet = [
  { src: img_8_small, setting: '500w' },
  { src: img_8_large, setting: '1426w' }
]
.map(item => `${item.src} ${item.setting}`)
.join(', ');

const img9SrcSet = [
  { src: img_9_small, setting: '500w' },
  { src: img_9_large, setting: '1426w' }
]
.map(item => `${item.src} ${item.setting}`)
.join(', ');

const img10SrcSet = [
  { src: img_10_small, setting: '500w' },
  { src: img_10_large, setting: '1426w' }
]
.map(item => `${item.src} ${item.setting}`)
.join(', ');

const img11SrcSet = [
  { src: img_11_small, setting: '500w' },
  { src: img_11_large, setting: '1426w' }
]
.map(item => `${item.src} ${item.setting}`)
.join(', ');

const img12SrcSet = [
  { src: img_12_small, setting: '500w' },
  { src: img_12_large, setting: '1426w' }
]
.map(item => `${item.src} ${item.setting}`)
.join(', ');


const photo2019 = [

  {
    srcSet: img7SrcSet,
    small: img_7_small,
    large: img_7_large,
    text: {
      title: '.\'Reversal:Untitled\'',
      info: '2019 175 x 160cm Oil & Acrylic on stitched canvas',
    }
  },
  {
    srcSet: img8SrcSet,
    small: img_8_small,
    large: img_8_large,
    text:{
      title: '.\'Utu\'',
      info: '2019 165 x 125cm Oil & Acrylic on stitched canvas (Explosion Series)',
    }

  },
  {
    srcSet: img9SrcSet,
    small: img_9_small,
    large: img_9_large,
    text: {
      title: '.\'Kilik\'',
      info: '2019 135 x 135cm Oil & Acrylic on stitched canvas (Explosion Series)',
    }
  },
  {
    srcSet: img10SrcSet,
    small: img_10_small,
    large: img_10_large,
    text: {
      title: '.\'Reinier\'',
      info: '2019 170 x 135cm Oil & Acrylic on stitched canvas (Explosion Series)',
    }
  },
  {
    srcSet: img11SrcSet,
    small: img_11_small,
    large: img_11_large,
    text: {
      title: '.\'Ada\' ',
      info: '2019 170 x 165cm Oil & Acrylic on stitched canvas (Explosion Series)',
    }
  },
  {
    srcSet: img12SrcSet,
    small: img_12_small,
    large: img_12_large,
    text: {
      title: '.\'Otto\'',
      info: '2019 150 x 150cm Oil & Acrylic on stitched canvas (Explosion Series)',
    }
  }

];




function WorksPage(props) {
  return (
        <Carousel photos={photo2019} arrows={true} />
  );
}

export default WorksPage;
