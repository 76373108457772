import React from 'react';
import ContactForm from '../components/ContactForm';


function ContactPage(props) {
  return (

    <div className="content-box-sans-photo pt-5">
      <ContactForm />
      

    </div>





  );
}

export default ContactPage;
