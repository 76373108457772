import React from "react";
import Carousel from "../components/Carousel";

//2021 + images

import img_13_small from "../assets/images/2021/small/bloom.jpg";
import img_13_large from "../assets/images/2021/large/bloom.jpg";

import img_14_small from "../assets/images/2021/small/tvdinner.jpg";
import img_14_large from "../assets/images/2021/large/tvdinner.jpg";

import img_15_small from "../assets/images/2021/small/untitled24.jpg";
import img_15_large from "../assets/images/2021/large/untitled24.jpg";

//2020 images

import img_1_small from "../assets/images/2020/small/sam-mills-art-2020-a1.jpg";
import img_1_large from "../assets/images/2020/large/sam-mills-art-2020-a.jpg";

import img_2_small from "../assets/images/2020/small/sam-mills-art-2020-b1.jpg";
import img_2_large from "../assets/images/2020/large/sam-mills-art-2020-b.jpg";

import img_3_small from "../assets/images/2020/small/sam-mills-art-2020-c1.jpg";
import img_3_large from "../assets/images/2020/large/sam-mills-art-2020-c.jpg";

import img_5_small from "../assets/images/2020/small/sam-mills-art-2020-e1.jpg";
import img_5_large from "../assets/images/2020/large/sam-mills-art-2020-e.jpg";

import img_6_small from "../assets/images/2020/small/sam-mills-art-2020-f1.jpg";
import img_6_large from "../assets/images/2020/large/sam-mills-art-2020-f.jpg";

//2019 images

import img_7_small from "../assets/images/2019/small/sam-mills-art-2019-f1.jpg";
import img_7_large from "../assets/images/2019/large/sam-mills-art-2019-f.jpg";

import img_8_small from "../assets/images/2019/small/sam-mills-art-2019-a1.jpg";
import img_8_large from "../assets/images/2019/large/sam-mills-art-2019-a.jpg";

import img_9_small from "../assets/images/2019/small/sam-mills-art-2019-b1.jpg";
import img_9_large from "../assets/images/2019/large/sam-mills-art-2019-b.jpg";

import img_10_small from "../assets/images/2019/small/sam-mills-art-2019-c1.jpg";
import img_10_large from "../assets/images/2019/large/sam-mills-art-2019-c.jpg";

import img_11_small from "../assets/images/2019/small/sam-mills-art-2019-d1.jpg";
import img_11_large from "../assets/images/2019/large/sam-mills-art-2019-d.jpg";

import img_12_small from "../assets/images/2019/small/sam-mills-art-2019-e1.jpg";
import img_12_large from "../assets/images/2019/large/sam-mills-art-2019-e.jpg";

const img13SrcSet = [
  { src: img_13_small, setting: "500w" },
  { src: img_13_large, setting: "1426w" },
]
  .map((item) => `${item.src} ${item.setting}`)
  .join(", ");

const img14SrcSet = [
  { src: img_14_small, setting: "500w" },
  { src: img_14_large, setting: "1426w" },
]
  .map((item) => `${item.src} ${item.setting}`)
  .join(", ");

const img15SrcSet = [
  { src: img_15_small, setting: "500w" },
  { src: img_15_large, setting: "1426w" },
]
  .map((item) => `${item.src} ${item.setting}`)
  .join(", ");

const img1SrcSet = [
  { src: img_1_small, setting: "500w" },
  { src: img_1_large, setting: "1426w" },
]
  .map((item) => `${item.src} ${item.setting}`)
  .join(", ");

const img2SrcSet = [
  { src: img_2_small, setting: "500w" },
  { src: img_2_large, setting: "1426w" },
]
  .map((item) => `${item.src} ${item.setting}`)
  .join(", ");

const img3SrcSet = [
  { src: img_3_small, setting: "500w" },
  { src: img_3_large, setting: "1426w" },
]
  .map((item) => `${item.src} ${item.setting}`)
  .join(", ");

const img5SrcSet = [
  { src: img_5_small, setting: "500w" },
  { src: img_5_large, setting: "1426w" },
]
  .map((item) => `${item.src} ${item.setting}`)
  .join(", ");

const img6SrcSet = [
  { src: img_6_small, setting: "500w" },
  { src: img_6_large, setting: "1426w" },
]
  .map((item) => `${item.src} ${item.setting}`)
  .join(", ");

const img7SrcSet = [
  { src: img_7_small, setting: "500w" },
  { src: img_7_large, setting: "1426w" },
]
  .map((item) => `${item.src} ${item.setting}`)
  .join(", ");

const img8SrcSet = [
  { src: img_8_small, setting: "500w" },
  { src: img_8_large, setting: "1426w" },
]
  .map((item) => `${item.src} ${item.setting}`)
  .join(", ");

const img9SrcSet = [
  { src: img_9_small, setting: "500w" },
  { src: img_9_large, setting: "1426w" },
]
  .map((item) => `${item.src} ${item.setting}`)
  .join(", ");

const img10SrcSet = [
  { src: img_10_small, setting: "500w" },
  { src: img_10_large, setting: "1426w" },
]
  .map((item) => `${item.src} ${item.setting}`)
  .join(", ");

const img11SrcSet = [
  { src: img_11_small, setting: "500w" },
  { src: img_11_large, setting: "1426w" },
]
  .map((item) => `${item.src} ${item.setting}`)
  .join(", ");

const img12SrcSet = [
  { src: img_12_small, setting: "500w" },
  { src: img_12_large, setting: "1426w" },
]
  .map((item) => `${item.src} ${item.setting}`)
  .join(", ");

const photo2020 = [
  {
    srcSet: img1SrcSet,
    small: img_1_small,
    large: img_1_large,
    text: {
      title: ".'Mutiny:Redacted'",
      info: "2020 183 x 152cm Oil & Spray Paint on stitched canvas (Gossip Series)",
    },
  },
  {
    srcSet: img2SrcSet,
    small: img_2_small,
    large: img_2_large,
    text: {
      title: ".'Design:Committee'",
      info: "2020 150 x 120cm Oil & Spray Paint on stitched canvas (Gossip Series)",
    },
  },
  {
    srcSet: img3SrcSet,
    small: img_3_small,
    large: img_3_large,
    text: {
      title: ".'Mad:hatten'",
      info: "2020 185 x 160cm Oil Stick & Acrylic on stitched canvas",
    },
  },
  {
    srcSet: img5SrcSet,
    small: img_5_small,
    large: img_5_large,
    text: {
      title: ".'Untitled:Bust'",
      info: "2020 165 x 165cm Oil & Spray Paint on stitched canvas (Gossip Series)",
    },
  },
  {
    srcSet: img6SrcSet,
    small: img_6_small,
    large: img_6_large,
    text: {
      title: ".'Idle:Idol'",
      info: "2020 165 x 165cm Oil & Spray Paint on stitched canvas (Gossip Series)",
    },
  },
];
const photo2021 = [
  {
    srcSet: img13SrcSet,
    small: img_13_small,
    large: img_13_large,
    text: {
      title: ".'Bloom or Bust'",
      info: `2023. 190 x 130cm Oil, Oil Stick, Spray paint, Graphite pencil on stitched canvas`,
    },
  },
  {
    srcSet: img14SrcSet,
    small: img_14_small,
    large: img_14_large,
    text: {
      title: ".'TV Dinner'",
      info: ` 2024. 185 x 130cm Oil, Oil Stick, Acrylic, Spray Paint, Graphite pencil on stitched canvas`,
    },
  },
  {
    srcSet: img15SrcSet,
    small: img_15_small,
    large: img_15_large,
    text: {
      title: ".'Succession'",
      info: `2022-24. 190 x 145cm Oil, Oil Stick on canvas`,
    },
  },
];

const photo2019 = [
  {
    srcSet: img7SrcSet,
    small: img_7_small,
    large: img_7_large,
    text: {
      title: ".'Reversal:Untitled'",
      info: "2019 175 x 160cm Oil & Acrylic on stitched canvas",
    },
  },
  {
    srcSet: img8SrcSet,
    small: img_8_small,
    large: img_8_large,
    text: {
      title: ".'Utu'",
      info: "2019 165 x 125cm Oil & Acrylic on stitched canvas (Explosion Series)",
    },
  },
  {
    srcSet: img9SrcSet,
    small: img_9_small,
    large: img_9_large,
    text: {
      title: ".'Kilik'",
      info: "2019 135 x 135cm Oil & Acrylic on stitched canvas (Explosion Series)",
    },
  },
  {
    srcSet: img10SrcSet,
    small: img_10_small,
    large: img_10_large,
    text: {
      title: ".'Reinier'",
      info: "2019 170 x 135cm Oil & Acrylic on stitched canvas (Explosion Series)",
    },
  },
  {
    srcSet: img11SrcSet,
    small: img_11_small,
    large: img_11_large,
    text: {
      title: ".'Ada' ",
      info: "2019 170 x 165cm Oil & Acrylic on stitched canvas (Explosion Series)",
    },
  },
  {
    srcSet: img12SrcSet,
    small: img_12_small,
    large: img_12_large,
    text: {
      title: ".'Otto'",
      info: "2019 150 x 150cm Oil & Acrylic on stitched canvas (Explosion Series)",
    },
  },
];

function WorksPage(props) {
  return <Carousel photos={photo2021} arrows={true} />;
}

export default WorksPage;
